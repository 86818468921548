import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Switch from 'react-switch';
import Slider from 'rc-slider';

import {
  IoRadioButtonOffOutline,
  IoRadioButtonOnOutline,
  IoWallet,
} from 'react-icons/io5';

import {
  Loading,
  Header,
  SigninPage,
  Footer,
  Text,
  Notification,
  Root,
  NotFound,
} from 'components';
import { DEFAULT_GREY, PRIMARY_COLOR } from 'theme';
import {
  convertCurrencyFromText,
  getDefaultPaymentMethod,
  ToastZoomTransition,
} from 'utils/helper';
import api from 'utils/api';
import { updateUserData } from 'actions/user';
import './wallet.css';
import ApplePayButton from './PaymentRequestButton';
import { disableWallets } from 'config';

import 'rc-slider/assets/index.css';

const TopUpOptionCard = ({
  data,
  currency,
  isSelected,
  onClick,
  organizationColor,
}) => {
  const { wallet_balance, price } = data;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#EAEAEA',
        borderRadius: 4,
        padding: '12px 16px',
        margin: '8px 0',
        cursor: 'pointer',
        // opacity: isSelected ? 0.2 : 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        // border: `1px ${organizationColor} solid`,
      }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text size={18} weight={600} style={{ opacity: 1, textAlign: 'start' }}>
          Top Up {currency} {parseFloat(wallet_balance / 100).toFixed(2)}
        </Text>
        <Text size={12} style={{ opacity: 1, textAlign: 'start' }}>
          You will pay {currency} {parseFloat(price / 100).toFixed(2)}
        </Text>
      </div>
      {isSelected ? (
        <IoRadioButtonOnOutline color="black" />
      ) : (
        <IoRadioButtonOffOutline />
      )}
    </div>
  );
};

const AddBalanceButton = ({
  buttonText,
  selectedOption,
  backgroundColor,
  onClick,
  processing,
}) => {
  const _backgroundColor = processing
    ? 'rgba(128,128,128,0.5)'
    : selectedOption?._id
    ? backgroundColor
    : '#FAFAFA';

  return (
    <div
      style={{
        backgroundColor: _backgroundColor,
        textAlign: 'center',
        padding: 16,
        cursor: 'pointer',
        borderRadius: 4,
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12)',
      }}
      onClick={onClick}
    >
      <Text size={20} color={selectedOption?._id ? 'white' : 'black'}>
        {buttonText}
      </Text>
    </div>
  );
};

const BalanceCard = ({ transaction, organizationColor }) => {
  if (transaction.amount > 0) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 0',
        borderBottom: '1px solid #DADADA',
      }}
    >
      <div>
        <IoWallet
          size="1.5em"
          color={organizationColor}
          style={{ marginRight: 16 }}
        />
      </div>
      <Text size={16} style={{ flex: 2 }}>
        {transaction.description}
      </Text>
      <Text size={14} style={{ flex: 1, textAlign: 'end' }}>
        {moment(new Date(transaction.createdAt * 1000)).format('D MMM')}
      </Text>
    </div>
  );
};

const Index = ({ organization, user, status, auth0User }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;
  const [customer, setCustomer] = useState({});
  const currency = convertCurrencyFromText(organization.stripe_currency);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [topUpOptions, setTopUpOptions] = useState([]);
  const [selectedTopUpOption, setSelectedTopUpOption] = useState({});
  const [processing, setProcessing] = useState(false);
  const [autoTopUp, setAutoTopUp] = useState(user.auto_top_up_wallet);
  const [autoTopUpAmount, setAutoTopUpAmount] = useState(
    user?.auto_top_up_amount ?? organization.stripe_reserve_amount
  );
  const [customBalanceTransactions, setCustomBalanceTransactions] = useState(
    []
  );
  const [notificationMessage, setNotificationMessage] = useState(
    'Your payment has been processed. Your balance will update within a minute.'
  );
  const [notificationRedirectTo, setNotificationRedirectTo] = useState('');
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(
      organization.configurations?.test_mode
        ? process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
        : process.env.REACT_APP_PRODUCTION_STRIPE_KEY,
      {
        apiVersion: '2020-08-27',
        stripeAccount: organization.stripe_connected_account_id,
      }
    )
  );

  const init = async () => {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      dispatch(updateUserData(token));

      const response_wallet = await api.get(token, 'v1/wallet');
      setCustomer(response_wallet.result.customer);
      setCustomBalanceTransactions(
        response_wallet.result.custom_balance_transactions
      );

      const response_products = await api.get(token, 'v1/products');
      setTopUpOptions(response_products.result);

      //TODO: Hardcoded product
      setSelectedTopUpOption(response_products.result[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const refreshInterval = setInterval(async () => {
        let token = await getAccessTokenSilently();
        const response_wallet = await api.get(token, 'v1/wallet');
        setCustomer(response_wallet.result.customer);
        setCustomBalanceTransactions(
          response_wallet.result.custom_balance_transactions
        );
      }, 10000);

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, []);

  useEffect(() => {
    setAutoTopUp(user.auto_top_up_wallet);
    setAutoTopUpAmount(
      user?.auto_top_up_amount ?? organization.stripe_reserve_amount
    );
  }, [user]);

  const updateUser = async (autoTopUp, autoTopUpAmount) => {
    let token = await getAccessTokenSilently();
    await api.patch(token, `v1/users/${user._id}`, {
      auto_top_up_wallet: autoTopUp,
      auto_top_up_amount: autoTopUpAmount,
    });
  };

  const buttonText = () => {
    if (!selectedTopUpOption?._id) {
      return 'Please select an amount';
    }

    if (user && user.stripe_default_payment_method) {
      const paymentMethod = getDefaultPaymentMethod(
        user?.stripe_default_payment_method,
        user?.stripe_payment_methods
      );

      return `Pay ${currency} ${parseFloat(
        selectedTopUpOption.price / 100
      ).toFixed(2)} with ${paymentMethod.card.brand.toUpperCase()} ${
        paymentMethod.card.last4
      }`;
    } else {
      return 'Add Payment Method';
    }
  };

  const buttonClick = async () => {
    if (!selectedTopUpOption._id) return null;

    if (!user.stripe_default_payment_method) {
      history.push('/payment_methods');
    } else if (!processing) {
      if (window.confirm(`Are you sure you want to ${buttonText()}`)) {
        setProcessing(true);
        let token = await getAccessTokenSilently();
        const response = await api.post(token, 'v1/wallet/credit', {
          product_id: selectedTopUpOption._id,
          payment_method_id: user.stripe_default_payment_method,
        });
        setProcessing(false);
        if (
          response.message !==
          'Your payment has been processed. Your balance will update within a minute.'
        ) {
          setNotificationMessage(
            'Card Declined. Please update your card from the Payment Methods menu.'
          );
        } else {
          setNotificationMessage(
            'Your payment has been processed. Your balance will update within a minute.'
          );
        }
        dispatch(updateUserData(token));
        setNotificationRedirectTo();
        setShowNotification(true);
        // setSelectedTopUpOption({});
      }
    }
  };

  if (loading) {
    return <Loading />;
  } else if (disableWallets(organization)) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Balance"
        showSupportChat={false}
      >
        <NotFound text="No Records Found" />
      </Root>
    );
  } else {
    return (
      <>
        <Notification
          message={notificationMessage}
          isShowing={showNotification}
          timeoutSeconds={5}
          hide={() => {
            setShowNotification(false);
          }}
          organizationColor={organizationColor}
          redirectTo={notificationRedirectTo}
        />
        <ToastContainer
          position="bottom-center"
          hideProgressBar
          autoClose={2500}
          transition={ToastZoomTransition()}
          closeButton={false}
        />
        <Root
          organization={organization}
          user={user}
          auth0User={auth0User}
          title="Balance"
          showSupportChat={false}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              // minHeight: '100vh',
            }}
          >
            <div
              style={{
                marginTop: 8,
                padding: '0 12px',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0',
                  borderRadius: 4,
                }}
              >
                {/* <Text size={14} weight={500}>
              Available Balance:
            </Text> */}
                <Text size={40} weight={600}>
                  {currency} {parseFloat(-customer.balance / 100).toFixed(2)}
                </Text>
                <div style={{ textAlign: 'end' }}>
                  <Text size={12}>
                    A balance of {currency}{' '}
                    {organization?.driver_application?.wallet?.minimum_balance
                      ? organization?.driver_application?.wallet
                          ?.minimum_balance
                      : organization.stripe_reserve_amount}{' '}
                    is required to start a transaction
                  </Text>
                </div>
              </div>
              {/* <div
                style={{
                  padding: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '8px 0',
                  // flex: 1,
                  backgroundColor: 'white',
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    margin: 4,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text size={18} weight={600}>
                    One Time Top Up
                  </Text>
                </div>
                {topUpOptions.map((eachTopUpOption) => (
                  <TopUpOptionCard
                    data={eachTopUpOption}
                    currency={currency}
                    isSelected={selectedTopUpOption._id === eachTopUpOption._id}
                    onClick={() => {
                      setSelectedTopUpOption(eachTopUpOption);
                    }}
                    organizationColor={organizationColor}
                  />
                ))}
              </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  padding: 16,
                  margin: '8px 0',
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '8px 0',
                    alignItems: 'center',
                    padding: '0 10px',
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      margin: 4,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Text size={18} weight={600}>
                      Automatic Top Up
                    </Text>
                  </div>
                  <div style={{ margin: 4 }}>
                    <Text size={18} weight={600}>
                      {currency}{' '}
                      {autoTopUpAmount
                        ? autoTopUpAmount
                        : organization.stripe_reserve_amount}
                    </Text>
                  </div>
                  <div style={{ margin: 4 }}>
                    <Switch
                      onChange={(checked) => {
                        if (user.stripe_default_payment_method) {
                          setAutoTopUp(checked);
                          updateUser(
                            checked,
                            organization.stripe_reserve_amount
                          );
                        } else {
                          setNotificationMessage(
                            'No payment method found. Please add a Credit Card first.'
                          );
                          setNotificationRedirectTo('/payment_methods');
                          setShowNotification(true);
                        }
                      }}
                      checked={autoTopUp}
                      onColor={organizationColor}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </div>
                </div>
                {/* <div style={{ margin: 12, paddingLeft: 11 }}>
                  <Slider
                    disabled={!autoTopUp}
                    min={organization.stripe_reserve_amount}
                    step={5}
                    value={autoTopUpAmount}
                    trackStyle={{
                      backgroundColor: organizationColor,
                      height: 12,
                    }}
                    railStyle={{
                      height: 8,
                    }}
                    onChange={(value) => {
                      setAutoTopUpAmount(value);
                    }}
                    onAfterChange={(auto_top_up_amount) => {
                      updateUser(autoTopUp, auto_top_up_amount);
                    }}
                    handleStyle={{
                      height: 22,
                      width: 22,
                      borderColor: autoTopUp ? organizationColor : DEFAULT_GREY,
                    }}
                    // style={{ height: !autoTopUp ? 22 : 14 }}
                  />
                </div> */}
                <div style={{ margin: '0 16px' }}>
                  {!autoTopUp ? (
                    <Text size={12}>Auto top up is disabled.</Text>
                  ) : (
                    <Text size={12}>
                      {currency} {autoTopUpAmount} will be charged{' '}
                      {user.stripe_payment_methods.map((eachPaymentMethod) => {
                        if (
                          eachPaymentMethod.id ===
                          user?.stripe_default_payment_method
                        ) {
                          return `using ${eachPaymentMethod?.card?.brand} ${eachPaymentMethod.card?.last4}`;
                        }
                      })}{' '}
                      when balance drops below {currency}{' '}
                      {organization.stripe_reserve_amount}.
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
          {selectedTopUpOption ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'fixed',
                bottom: 0,
                boxShadow:
                  'rgb(0 0 0 / 6%) 0px 1px 3px, rgb(0 0 0 / 12%) 0px 2px 6px',
                backgroundColor: 'white',
                padding: 16,
              }}
            >
              <div
                style={{
                  flex: 1,
                  margin: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 8,
                }}
              >
                <Text size={18} weight={600}>
                  Manual Top Up
                </Text>
              </div>
              <Elements stripe={stripePromise}>
                <ApplePayButton
                  style={{
                    margin: 0,
                    marginBottom: 12,
                    display: processing ? 'none' : '',
                  }}
                  selectedTopUpOption={selectedTopUpOption}
                  organization={organization}
                  onClick={(error) => {
                    if (error) {
                      console.log(error);
                      toast.info(error);
                    } else {
                      setNotificationMessage(
                        'Your payment has been processed. Your balance will update within a minute.'
                      );
                      setNotificationRedirectTo(null);
                      setShowNotification(true);
                    }
                  }}
                />
              </Elements>
              <div
                style={{
                  height: 60,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: processing ? 'rgba(128,128,128,0.5)' : '',
                  marginBottom: 12,
                  display: processing ? 'flex' : 'none',
                }}
              >
                <Text
                  style={{
                    font: '500 20px -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,sans-serif',
                  }}
                >
                  Pay Now
                </Text>
              </div>

              <AddBalanceButton
                backgroundColor={organizationColor}
                selectedOption={selectedTopUpOption}
                buttonText={buttonText()}
                onClick={buttonClick}
                processing={processing}
              />
            </div>
          ) : null}

          {customBalanceTransactions.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 16,
                padding: 30,
                borderRadius: 4,
                backgroundColor: 'white',
              }}
            >
              <Text size={18} style={{ margin: '8px 0' }} weight={600}>
                History
              </Text>

              {customBalanceTransactions.map((eachTransaction) => {
                return (
                  <BalanceCard
                    transaction={eachTransaction}
                    organizationColor={organizationColor}
                  />
                );
              })}
            </div>
          ) : null}
        </Root>
      </>
    );
  }
};
const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Index);
